import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'
import NewsSectionBlock from '../components/NewsSectionBlock'
import Typography from '../components/Common/Typography'

import * as s from '../pages/news.module.scss'

const AutomatedJobSiteDocumentation: React.FC = () => {
  return (
    <>
      <SEO
        title="The Real Power of Automated Job Site Documentation"
        description="Job site documentation maybe the most important thing on site. It contains all about how the building should look like and how to construct it in details. Documentation should be easy to understand and use on site."
      />
      <NewsSectionBlock>
        <Typography variant="h1" color="blue">
          The Real Power of Automated Job Site Documentation
        </Typography>
        <Typography variant="h2" color="blue">
          What is documentation
        </Typography>
        <Typography variant="body1">
          Job site documentation maybe the most important thing on site. It
          contains all about how the building should look like and how to
          construct it in details. Documentation should be easy to understand
          and use on site. Hundreds of years people worked with paper and 2D
          plans (sections, cuts, specs). The process is familiar to millions of
          workers, but is it effective?
        </Typography>
        <Typography variant="h2" color="blue">
          Automated job site documentation
        </Typography>
        <Typography variant="body1">
          During the last 20 years people invented some innovations how to work
          with 2D faster and have up to date information at every moment. These
          opportunities are closely connected with digital age. Documentation
          can be presented in electronic formats (dwg, pdf, docx, for example)
          and can be opened using laptops and tablet computers in mobile office
          or right at the construction site.
        </Typography>
        <Typography variant="body1">
          The second most important thing is Common data environment: the
          virtual vault where electronic documentation is kept and managed. It
          helps to have all project documents available in one place for all the
          participants, deal with versions and revisions, set workflows of
          coordination and issue management. If the job site documentation is
          once changed, everybody will know about it and start using up to date
          data.
        </Typography>
        <Typography variant="h2" color="blue">
          New formats
        </Typography>
        <Typography variant="body1">
          Digital transformation of construction gives us opportunities not only
          work with electronic 2D plans, but be much more effective saving time
          and money. Building information modeling (BIM) comes to construction
          site. Now you can use 3D model which represents the design and
          installation idea much better. Each element of the model is the
          prototype of real equipment or material with its properties and
          characteristics: quantity, dimensions, volume, weight, cost and more.
          That is why it’s so easy to understand what is needed to do, calculate
          the time and manage the process of works.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/automated-job-site-documentation/image-1.png"
            alt="Automated job site documentation"
            title="Automated job site documentation"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Another big opportunity – capturing the reality on site. Construction
          progress photo documentation can be obtained by different
          technologies, using UAV (drones) or laser scanning, for example. But
          probably the most affordable and comfortable way now is 360° photo
          technology.
        </Typography>
        <Typography variant="body1">
          SIGNAX INSPECTION module makes 360° coverage of job sites which is
          much more visual than just 2D site documentation or papers. It helps
          to have clear picture what is really happening on site saving hundreds
          of hours for construction teams, meetings with contractors and
          management decisions time.
        </Typography>
        <Typography variant="h2" color="blue">
          Advantages
        </Typography>
        <Typography variant="body1">
          360° cameras become cheaper now. You can put it at any internal point
          of constructed building and need only some seconds to make a photo.
          SIGNAX INSPECTION app automatically collects all the data. You can
          find the visual status of construction for needed day, compare it with
          the planned result and with BIM model.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/automated-job-site-documentation/image-2.png"
            alt="Automated job site documentation 1"
            title="Automated job site documentation 1"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          It is important to use new technologies and automate job site
          documentation from the early stages. In this case you will fully
          understand the entire process of works and will be sure that each
          report matches the real situation on site. The technology gives you
          the opportunity to control the project remotely at office or any
          place. Supervision specialists now can save the time and inspect a
          list of projects just using the screen of laptop.
        </Typography>
        <Typography variant="h2" color="blue">
          Case story
        </Typography>
        <Typography variant="body1">
          HALS Development Group that works in EMEA region automated the process
          of using job site documentation in its projects. The company
          implemented the reports from construction sites in 360° photo format
          in the SIGNAL INSPECTION module.
        </Typography>
        <Typography variant="body1">
          Traditionally, on-site photos were uploaded to messenger chats or
          stored on a hard drive. They could be found and used immediately after
          the creation, but after a short time the probability of successfully
          finding the necessary information was reduced to zero.
        </Typography>
        <Typography variant="body1">
          With the help of SIGNAL INSPECTION technology, it became possible to
          remotely monitor the volume and quality of work performed at the
          facility in a format convenient for everyone. HALS Development Group
          is able to ensure the transfer of information from the construction
          stage to the operation thanks to a structured repository of images
          taken on a regular basis from certain points of the construction site.
          Monitoring of the real situation at the construction site makes the
          process of working with job site documentation faster in 4 times.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default AutomatedJobSiteDocumentation
